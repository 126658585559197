<template>
<div>
    <div class="my-6 font-weight-bold">Оберіть період нарахувань:</div>
    <v-date-picker full-width :allowed-dates="allowedMonths" :show-current="false" color="primary" :min="lastMonth" :max="maxMonth" v-model="picker" type="month" @change="loadData()"></v-date-picker>    
</div>
</template>

<script>
import {
    mapActions,
    mapMutations
} from 'vuex'

export default {
    data() {
        return {
            picker: '',
            minMonth: '',
            maxMonth: ''

        }
    },
    computed: {
      lastMonth() {
            const last = this.$store.state.motivation.allBonusList[this.$store.state.motivation.allBonusList.length - 1] || {}
            return last.month
        },
    },
    watch: {
        picker(v) {
            this.setPeriod(v)
        }
    },
    methods: {
        ...mapActions(['touch', 'getMotivationsBonusList']),
        ...mapMutations(['setPeriod']),
        loadData() {
            this.$store.commit('setMotivationFiltersShow', false)
            this.getMotivationsBonusList({
                id: this.$route.params.id,
                period: this.picker
            })
        },
        allowedMonths(v) {
            return this.$store.state.motivation.allBonusList.find(el => el.month == v)
        },
        setCurrentMonth() {
            //При заході на сторінку у фільтрі вибраний місяць, який вираховується:
            //Якщо поточний день < 10, то
            //-	Місяць = поточний – 2
            //Інакше
            //-	Місяць = поточний – 1

            let date = new Date()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            if (date.getDate() < 10) {
                month = month - 2
                if (month <= 0) {
                    month = month + 12
                    year = year - 1
                }
            } else {
                month = month - 1
                if (month <= 0) {
                    month = month + 12
                    year = year - 1
                }
            }
            if (month < 10) {
                month = '0' + month
            }
            this.picker = year + '-' + month
        },
        setMaxMonth() {
            let date = new Date()
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            month = month - 1
            if (month <= 0) {
                month = month + 12
                year = year - 1
            }
            if (month < 10) {
                month = '0' + month
            }
            this.maxMonth = year + '-' + month
        },
    },
    async mounted() {
        await this.touch()
        this.setCurrentMonth()
        this.setMaxMonth()
        this.loadData()
    }
}
</script>
