<template>
    <v-container fluid v-if="show">
        <v-card flat width="100%">
        <h1> {{ motivationName }}</h1>
        <v-card flat tile width="100%" class="mt-2" v-if="$vuetify.breakpoint.smAndDown">
            <v-btn color="primary" block dark @click.stop="drawerOpenClose()">
                Фільтри
                <v-icon right>mdi-filter-menu-outline</v-icon>
            </v-btn>
            <v-navigation-drawer v-model="motivationFiltersShow" stateless clipped temporary app width="320" class="px-2">
                <MotivationFiltersBlock collapsed />
            </v-navigation-drawer>
        </v-card>
        <v-row no-gutters>
            <v-col xl="2" lg="2" md="3" class="pr-2" v-if="$vuetify.breakpoint.mdAndUp">
                <MotivationFiltersBlock />
            </v-col>
            <v-col xl="10" lg="10" md="9">
                <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="confirmedFalse">
                    Немає доступу до інформації. Ваші контактні дані не підтверджені менеджером.
                </v-alert>
                <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="confirmedFalse">
                    Немає доступу до інформації. Ваші контактні дані не підтверджені менеджером.
                </v-alert>
                <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="contractorsFalse">
                    Немає жодного доступного контрагента для перегляду виплат.
                </v-alert>
                <v-card class="px-0 py-6 px-md-6 text-center" flat>
                    <h2 class="mb-6">Шановний партнере!</h2>
                    <v-data-iterator :loading="bonusListLoading" :items="bonusList" hide-default-footer>
                        <template v-slot:loading>
                            <div class="py-10 body-2 grey--text text-center font-weight-light">Зачекайте...</div>
                        </template>
                        <template v-slot:no-data>
                            <div class="py-4 grey--text">Нажаль, у період <span class="title primary--text text-capitalize mx-2">{{ cPeriod }}</span> ви не виявили активності по програмі і тому не маєте нарахувань.</div>
                        </template>
                        <template v-slot:default="{ items, isExpanded, expand }">
                            <div class="mb-10">Як участником програми<br>у період <span class="title primary--text text-capitalize mx-2">{{ cPeriod }}</span> Вами было отримано нарахування:</div>
                            <v-row class="d-flex justify-space-around">
                                <v-col v-for="(item, key) in items" :key="key" sm="6" md="5" lg="4" class="my-4 d-flex justify-center"> 
                                    <MotivationBonusBlock :item="item" />
                                </v-col>
                            </v-row>
                        </template>

                    </v-data-iterator>
                </v-card>
            </v-col>

        </v-row>
    </v-card>
    </v-container>
</template>

<script>
import {
    mapActions,
} from 'vuex'
import MotivationFiltersBlock from './MotivatonFiltersBlock.vue'
import MotivationBonusBlock from './MotivationBonusBlock.vue'
export default {
    components: {
        MotivationFiltersBlock,
        MotivationBonusBlock
    },
    props: {},
    data() {
        return {
            show: false,
            confirmedFalse: false,
            contractorsFalse: false,

        }
    },
    methods: {
        ...mapActions(['touch', 'getContactInfo', 'getContactMotivationList', 'getAllMotivations', 'getMotivationsBonusList']),
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.log(error)
                return ''
            }
            return value
        },
        formatDate(date) {
            const d = new Date(date)
            const options = {
                year: 'numeric',
                month: 'long',
            }
            const ukrainianFormatter = new Intl.DateTimeFormat('uk-UA', options)
            return ukrainianFormatter.format(d).slice(0, -2)            
        },
        drawerOpenClose() {
            this.$store.commit('setMotivationFiltersShow', !this.motivationFiltersShow);
        },
    },
    watch: {
        '$route.params'(v) {
            this.getMotivationsBonusList({
                id: v.id
            })
        },
    },
    mounted() {
        this.getMotivationsBonusList({
            id: this.$route.params.id
        })
    },
    created() {
        this.getContactInfo()
            .then(async data => {
                if (data.groups && data.groups.cl_corp_bonus && !data.isSellToEmployee) {
                    this.$vuetify.goTo(0)
                    if (data.isConfirmed) {
                        await this.getAllMotivations()
                        await this.getContactMotivationList()
                        if (!this.motivationExists) {
                            this.$router.push('/404')
                        } else if (!this.motivationAvail) {
                            this.$router.push('/403')
                        } else {
                            this.show = true
                        }

                    } else {
                        this.show = true
                        this.confirmedFalse = true
                    }
                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.log(error))
    },
    computed: {
        motivationName() {
            if (!this.$store.state.motivation.list || !this.$store.state.motivation.list.length) return ''
            return this.$store.state.motivation.list.find(el => el.id == this.$route.params.id).name
        },
        motivationAvail() {
            if (!this.$store.state.motivation.list || !this.$store.state.motivation.list.length) return false
            return this.$store.state.motivation.list.find(el => el.id == this.$route.params.id) ? true : false
        },
        motivationExists() {
            if (!this.$store.state.motivation.allList || !this.$store.state.motivation.allList.length) return []
            return this.$store.state.motivation.allList.find(el => el.id == this.$route.params.id) ? true : false
        },
        bonusList() {
            return this.$store.state.motivation.bonusList || []
        },
        bonusListLoading() {
            return this.$store.state.motivation.bonusListLoading
        },
        cPeriod() {
            return this.formatDate(this.$store.state.motivation.cPeriod)
        },
        motivationFiltersShow: {
            get: function () {
                return this.$store.state.motivation.motivationFiltersShow
            },
            set: function (v) {
                this.$store.commit('setMotivationFiltersShow', v)
            }
        },
    }
}
</script>
