<template>
    <MotivationPage />
</template>

<script>
import {
    mapActions
} from 'vuex'
import MotivationPage from '../components/MotivationPage.vue'


export default {
    data: () => ({
    }),
    components: {
        MotivationPage
    },
    methods: {
    },
    mounted() {
    }
}
</script>

