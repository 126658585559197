<template>
<v-card width="100%" min-width="300" class="pa-4 d-flex flex-column justify-space-between">
    <v-card-title class="title text-center">{{ item.name }}</v-card-title>
    <v-divider class="mx-4"></v-divider>
    <h1 class="my-10" v-if="!loading">{{ format(bonus_uah) }} <span class="caption">₴</span></h1>
    <span class="text-center">
        <v-progress-circular class="ma-6" size="100" width="7" v-if="loading" indeterminate color="primary"></v-progress-circular>
    </span>

    <div class="caption" v-if="document">Документ нарахування: {{ document }}</div>
    <div class="caption green--text" v-if="!document && !loading">очікує нарахування</div>
    <div class="caption text-right mt-2">№{{ item.id }}</div>
</v-card>
</template>

<script>
import {
    mapActions
} from 'vuex'

export default {
    props: {
        item: Object,
    },
    data() {
        return {
            loading: false,
            bonus_uah: 0,
            document: '',
        }
    },
    methods: {
        ...mapActions(['touch', 'getBonusData']),
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.log(error)
                return ''
            }
            return value
        },
        async loadApiBonusData() {
            this.loading = true
            const token = this.getLocalStorage('authToken')
            const data = await this.getBonusData({
                bonus_id: this.item.id,
                token
            })
            if (data.content && data.content.bonus_uah) {
                const key = `${this.item.id}_${this.item.period}`
                this.bonus_uah = data.content.bonus_uah[key]
                if (data.content.calc[key] && data.content.calc[key][0] && data.content.calc[key][0].range_loop[0]) {
                    this.document = data.content.calc[key][0].range_loop[0].typhoon_docs
                } else {
                    this.document = ''
                }
            }
            this.loading = false
        },
        format(v) {
            if (v) {
                return Number(String(v).replace(/\s+/g, '')).toLocaleString('ru-RU')
            } else {
                return 0
            }
        }
    },
    watch: {
        item(v) {
            this.bonus_uah = 0
            this.document = ''
            this.loadApiBonusData()
        }
    },
    mounted() {
        this.loadApiBonusData()
    },
}
</script>
